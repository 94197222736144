<template>
  <section class="page">
    <div class="loader is-visible"></div>
  </section>
</template>

<script>
export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Add Supplier`;
  },
  methods: {
    addSupplier() {
      if (this.$store.user.isSupplier) {
        // Redirect to editing page if user has already added supplier
        this.$router.replace({
          name: 'supplierEdit',
          params: { id: this.$store.user.company.id },
        });
      } else {
        // Otherwise, create supplier first and then redirect
        this.$store.suppliers
          .addSupplier()
          .then(({ id }) => {
            this.$router.replace({
              name: 'supplierEdit',
              params: { id },
              query: this.$store.user.isAdmin ? null : { guide: true },
            });
          })
          .catch((error) => console.log(error));
      }
    },
  },
  mounted() {
    this.addSupplier();
  },
};
</script>
